import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import Home from '@/components/home.vue'
import Login from '@/views/login/index.vue'

 const routes = [
    { path: '/', component: Home },
    { path: '/login', component: Login }
]



const router = new Router({
    routes: routes
})

export default router