<template>
  <div>

    <el-container>
      <el-header style="height: 5vh;line-height: 5vh">视频推流后台管理系统</el-header>
      <el-container>
        <el-aside width="200px">


        </el-aside>
        <el-container>
          <el-main>
            <el-row style="height: 70px;line-height: 70px">
              <el-button  icon="el-icon-circle-plus-outline" type="primary" @click="formVisible=true">添加摄像头</el-button>
            </el-row>

            <el-table
                :data="list"
                style="width: 100%">
              <el-table-column
                  prop="liveName"
                  label="摄像头名称"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="pushDescUrl"
                  label="推流地址"
                  width="600">
              </el-table-column>
              <el-table-column
                  prop="pushSrcUrl"
                  label="摄像头IP地址"
                  width="280">
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="添加时间">
              </el-table-column>


              <el-table-column label="推流状态">
                <template  v-slot="slotProps">
                  <p v-if="slotProps.row.workerStatus.workerStatus==0" style="color: green">推流中</p>
                  <p v-if="slotProps.row.workerStatus.workerStatus==1" style="color: red">未推流</p>
                  <p v-if="slotProps.row.workerStatus.workerStatus==2">已停止</p>
                  <p v-if="slotProps.row.workerStatus.workerStatus==3">等待启动中</p>
                  <p v-if="slotProps.row.workerStatus.workerStatus==0">已推流时长:{{slotProps.row.workerStatus.runningTime}} </p>
                </template>
              </el-table-column>


              <el-table-column label="操作">
                <template  v-slot="slotProps">

                  <el-button type="text" size="mini" icon="el-icon-video-camera-solid" @click="addPush(slotProps.row)" >推流</el-button>

                  <el-button type="text" size="mini" @click="stopPush(slotProps.row)"  v-if="slotProps.row.workerStatus.workerStatus==3||slotProps.row.workerStatus.workerStatus==0">停止推流</el-button>
                  <el-button type="text" size="mini" icon="el-icon-edit" @click="editItem(slotProps.row)" >修改</el-button>
                  <el-button type="text" size="mini" icon="el-icon-delete" @click="removeItem(slotProps.row)" >删除</el-button>
                </template>
              </el-table-column>

            </el-table>
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100,500]"
                :page-size="listQuery.limit"
                :total="total"
                @size-change="changeSize"
                @current-change="fetchPage"
                @prev-click="fetchPrev"
                @next-click="fetchNext">
            </el-pagination>

          </el-main>
          <el-footer style="display: flex;justify-content: center;align-items: center">@copyright 2000-2024 hj_video_push </el-footer>
        </el-container>
      </el-container>
    </el-container>

    <el-dialog
        title="添加地址"
        :visible="formVisible"
        width="70%">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="摄像头名称"  >
              <el-input v-model="form.liveName" minlength=1></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="推流地址"  >
              <el-input v-model="form.pushDescUrl" minlength=1></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="摄像头地址"  >
              <el-input v-model="form.pushSrcUrl" minlength=1></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="formVisible = false">取消</el-button>
        </el-form-item>

      </el-form>
    </el-dialog>


  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      formVisible:false,
      listQuery: {
        page: 1,
        limit: 20,
        id: undefined,
        p_phone:undefined,
        phone:undefined,
        level:undefined,
      },
      total: 0,
      list: null,
      listLoading: true,
      selRow: {},
      form: {
        id:'',
        liveName:'',
        pushSrcUrl:'',
        pushDescUrl:''
      }
    }
  },
  computed: {

    //表单验证
    rules() {
      return {
      }
    }
  },
  created() {
    this.init();
  },
  methods:{
    resetForm(){
      this.form={id:'', liveName:'', pushSrcUrl:'', pushDescUrl:''}
    },
    addPush(formData){
      axios.post( 'http://localhost:8092/pushLive/pushers',formData).then(response => {
        this.fetchData();
        this.$message({
          message: '推流开始',
          type: 'success'
        });
      })
    },
    stopPush(formData){
      axios.delete( 'http://localhost:8092/pushLive/pushers?id='+formData.id,formData).then(response => {
        this.$message({
          message: '推流已结束',
          type: 'success'
        });
        this.fetchData();

      })
    },
    init() {
      this.fetchData()
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const formData = this.form;
          if(formData.id && formData.id!=''){
            axios.post( 'http://localhost:8092/pushLive',formData).then(response => {
              this.fetchData();
              this.formVisible = false;
            })
          }else{
            axios.put( 'http://localhost:8092/pushLive',formData).then(response => {
              this.fetchData();
              this.formVisible = false;
            })
          }
          this.resetForm();


        }
      })
    },
    fetchData() {

      axios.get('http://localhost:8092/pushLive/list')
          .then(response => {
            // console.log(JSON.stringify(response))
            this.list = response.data.data.records
              this.listLoading = false
              this.total = response.data.data.total
          }) .catch(error => {
            console.error('There was an error!', error);
          });

      // memberApi.getList(this.listQuery).then(response => {
      //   this.list = response.data.records
      //   this.listLoading = false
      //   this.total = response.data.total
      // })
    },
    fetchNext() {
      this.listQuery.page = this.listQuery.page + 1
      this.fetchData()
    },
    fetchPrev() {
      this.listQuery.page = this.listQuery.page - 1
      this.fetchData()
    },
    fetchPage(page) {
      this.listQuery.page = page
      this.fetchData()
    },
    changeSize(limit) {
      this.listQuery.limit = limit
      this.fetchData()
    },
    editItem(record){
      this.form = record;
      this.formVisible = true;

    },
    removeItem(record){
      axios.delete('http://localhost:8092/pushLive?id='+record.id,{id:record.id})
          .then(response => {
              this.fetchData();
          })
    },
  },
}
</script>

<style scoped>
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: left;


}

.el-aside {
  background-color: #D3DCE6;
  color: #000;
  text-align: left;
  height: 95vh;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: left;

}
</style>
